import React, { useState, useEffect, useRef, useMemo } from 'react';

import { withRouter } from 'react-router-dom';
import { PageBootstrap, ActivityOptionSelector } from '../../components';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from '../../util/reactIntl';

import ReactDOM from 'react-dom';
import { searchListings, STARTING_MAP_LOCATION } from './MapPage.duck';

import { TopbarContainer } from '../../containers';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { faLocationPin } from '@fortawesome/pro-solid-svg-icons';

import { IntlProvider } from '../../util/reactIntl';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
const activitiesOptions = findOptionsForSelectFilter('activities', config.custom.filters);

import Map, { Marker, Popup } from 'react-map-gl';
import './MapPage.scss';

const PopupContent = props => {
  const { listing } = props;
  const coverImage = listing.images[0].attributes.variants.default.url;

  return (
    <IntlProvider locale={config.locale} textComponent="span">
      <div className="card" style={{ width: '20rem', margin: '0px' }}>
        <img src={coverImage} className="card-img-top" />
        <div className="card-body">
          <h5 className="card-title">{listing.attributes.title}</h5>
          <a href={`/l/${listing.id.uuid}`} className="card-link">
            Learn more
          </a>
        </div>
      </div>
    </IntlProvider>
  );
};
const PrettyMarker = ({ listing }) => {
  const activity = listing.attributes.publicData.activities[0];
  const icon = activity ? activitiesOptions.find(a => a.key === activity).icon : faLocationDot;

  return (
    <div>
      <span className="fa-layers" style={{ color: 'grey' }}>
        <FontAwesomeIcon icon={faLocationPin} transform="grow-30" />
        <FontAwesomeIcon icon={icon} transform="up-8" inverse />
      </span>
    </div>
  );
};

const MapPageComponent = props => {
  const { listings, onSearch, searchInProgress, currentFilters, filters } = props;
  const mapRef = useRef();

  const onFilterSelected = filter => {
    if (searchInProgress) return;
    const { lng, lat } = mapRef.current.getCenter();
    onSearch('', { pub_activities: filter == 'all' ? '' : filter }, { lon: lng, lat: lat });
  };
  const onRefresh = () => {
    if (searchInProgress) return;
    const { lng, lat } = mapRef.current.getCenter();
    onSearch('', filters, { lon: lng, lat: lat });
  };

  const filterSelected = filters.pub_activities === '' ? 'all' : filters.pub_activities;
  return (
    <PageBootstrap>
      <TopbarContainer currentPage="MapPage" />

      <Container>
        <div className="py-4" />
        <div className="mt-4 mb-2">
          {(typeof window !== 'undefined') && (
            <Map
              ref={mapRef}
              initialViewState={{
                longitude: STARTING_MAP_LOCATION.lon,
                latitude: STARTING_MAP_LOCATION.lat,
                zoom: 5,
              }}
              style={{ height: '75vh' }}
              mapStyle="mapbox://styles/mapbox/streets-v9"
            >
              {listings.map(l => {
                const geo = l.attributes.geolocation;

                return (
                  <Marker key={l.id.uuid} longitude={geo.lng} latitude={geo.lat}>
                    <PrettyMarker listing={l} />
                  </Marker>
                );
              })}
            </Map>
          )}
        </div>
        <button className="btn btn-outline-dark" onClick={onRefresh}>
          Search here
        </button>
        <div className="my-2">
          <ActivityOptionSelector
            options={activitiesOptions}
            onSelect={onFilterSelected}
            selected={filterSelected}
          />
        </div>
      </Container>
    </PageBootstrap>
  );
};

const mapStateToProps = state => {
  const { listings, searchInProgress, filters } = state.MapPage;

  return {
    listings: listings,
    searchInProgress,
    filters,
  };
};

const mapDispatchToProps = dispatch => ({
  onSearch: (keywords, filters, origin) => dispatch(searchListings(keywords, filters, origin)),
});

const MapPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MapPageComponent);

export default MapPage;
